@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';
@import '../../node_modules/cropperjs/dist/cropper.min.css';

.slick-track {
  display: flex;
  align-items: center;
}

.slick-svg-dots > li {
  opacity: 0.5;
  &.slick-active {
    opacity: 1;
  }
}
